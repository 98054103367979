<template>
    <div class="ticket-page">
        <div v-if="info">
            <div class="top ">
                <van-cell :title="!info.linkman?'无名氏':info.linkman">
                    <div slot="default">
                        <span class="text-theme1">{{info.card_info.card_name}}</span>
                        <span class="text-theme3">￥{{info.amount}}</span>
                    </div>
                </van-cell>
            </div>
            <div class="van-cell font-16 img-wrap">
                <van-image :src="info.bar_code" width="60%" fit="cover"/>
                <p class="text-base">{{info.code}}</p>
                <van-image :src="info.code_qrcode" width="3rem" height="3rem" fit="cover"/>
                <p class="text-theme2 text-bold">{{info.pay_status_note}}</p>
            </div>
            <van-cell title="活动" :value="info.activity_name" center />
            <van-cell title="时间" :value="info.start_time" center/>

            <div class="tips font-12 text-grey">
                <p>温馨提示:</p>
                <p>1.请截图保存电子票，以便活动现场向主办方出示</p>
            </div>
        </div>

    </div>

</template>

<script>
    import {Cell, CellGroup, Icon, Image} from 'vant';
    import {tichets_info} from '@/request/api';

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Icon.name]: Icon,
            [Image.name]: Image
        },
        data() {
            return {
                activity_name: '',
                info: false,
                id: 0,
                activity_id:0
            }
        },
        mounted: function () {
            this.id = this.$route.query.id;
            this.activity_id = this.$route.query.activity;
            if(this.id>0){
                this.loadData();
            }else if(this.activity_id>0){
                this.scanData();
            }else{
                this.$toast.fail("参数缺乏");
            }
        },
        methods: {
            loadData() {
                if (this.id <= 0) {
                    this.$toast.fail("订单不存在");
                    return;
                }
                let param = {id: this.id};
                tichets_info(param).then(res => {
                    this.info = res.data;
                }).catch(error => {
                    this.$toast.fail("获取订单数据失败");
                });
            },
            scanData(){
                if (this.activity_id <= 0) {
                    this.$toast.fail("票据不存在");
                    return;
                }
                let param = {activity_id: this.activity_id};
                tichets_info(param).then(res => {
                    if(res.code!=200){
                        this.$dialog.confirm({title:'提示',message:res.msg}).then(res=>{

                        }).catch(error=>{

                        });
                    }else{
                        this.info = res.data;
                    }
                }).catch(error => {
                    this.$toast.fail("获取订单数据失败");
                });
            },
            callphone(phone) {
                window.location.href = 'tel://' + phone;
            },
            toInvitation(id) {
                this.$router.push({path: '/activity/ambassador/invitation',query:{id:id}});
            }
            
        }
    }
</script>

<style lang="less">
    .ticket-page {

        .tips {
            padding: 0.26667rem 0.42667rem;
            p {
                line-height: 20px;
            }
        }
        .van-cell__value {
            flex: 5;
        }
        .img-wrap {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
            p {
                margin: 10px 0;
            }
        }
    }
</style>